export const GENDER = [
    {
      id: '1',
      name: 'Унисекс',
      url: 'unisex',
    },
    {
      id: '2',
      name: 'Женский',
      url: 'female',
    },
    {
      id: '3',
      name: 'Мужской',
      url: 'male',
    },
  ];