// Товары, категории и бренды которые нельзя удалить из деплоя. Иначе кто-нибудь сможет удалить весь контент и посмотреть будет нечего.

export const LOCKED_PRODUCTS = [
  '-N8A2zojV8XVTsmxnZx3',
  '-N8A3LfAZ8Zt2mSijj65',
  '-N8A3nQO6p9RE70SEiug',
  '-N8A4-kQ9V12f0Jz3ZtE',
  '-N8A4FC8WxCqf6jroYPc',
  '-N8A4WD_p9jQEcrGjoEl',
  '-N8A4lOshqFchlJa_1sJ',
  '-N8A5-JM_3AB81-ECFY3',
  '-N8Af7JnfP3YDw3QoXTq',
  '-N8Alqk0IWO1B4nmYfJx',
  '-N8Am6miwoJ5sK9vH0C0',
  '-N8Pd1ol-0fB0OdzqBoU',
  '-N8PdOpuanE36gKx48y-',
  '-N8Pdcxl87QwKc0u52Pd',
  '-N8Pe4sRGjpjblBNe6lT',
  '-N8PeIIhpMbFBVdtpf2y',
  '-N91z-XEYSFVPkEwvBN8',
  '-N91z6RVnzApYgHAAeJn',
  '-N91zDQ8GPnSI3q4BNRe',
  '-N91zL4spV4AWoZWSwvb',
  '-N91zSkKe2OV2JQ12dU0',
  '-N91zapiSjdJnEt3g_bo',
  '-N91zilvT1LWvjwU_BFc',
  '-N91zvLy3lrbj-ftWXe9',
  '-N92-2SQTvJJo59JESis',
  '-N92-A_jKeB657iiFSnL',
  '-N92-Jekn8LKimiOcynG',
  '-N92-vsunOs6Kb0cUYtf',
  '-N9201Fv32dOT5hn2nXo',
  '-N9209WRt9GprPEytevX',
  '-N920_Dpzm4hUs8bz-bi',
  '-N920oxMcKGakCI8JAom',
  '-N9216PTYwLu3u5Yg2Js',
  '-N921pIwF4-4atre7X3k',
  '-N9222zukYn2fbyIGbk6',
  '-N922ASTo-82kHxAVm6o',
  '-N92RiqF0DMUDmX5NHF0',
  '-N92Rq_guq2qGYleBmNl',
  '-N92S0hFWlOwUP3yYyfu',
  '-N92SQ9LSvpb5cDz0Chi',
  '-N92SXV7ZoxaYDqhnX5_',
  '-N92Sfndctcu3YSdZr38',
  '-N92SmfC9QZ4GNo5EfyG',
  '-N92T3T4vBMzo7cKuoOL',
  '-N92TIc5AIKpqGiEMdqw',
  '-N92TPhNTTaHXRKE1hZn',
  '-N92TXKV6Fa7qt5uKftc',
  '-N92Te80BpPHm2xJqiqG',
  '-N92UBPNxQgjtzisXgs9',
  '-N92UIOnSlTKTnLrRXfa',
  '-N92UOS4YRbT-E7NXtjT',
  '-N92UUMaPXElbMuHiHJQ',
  '-N92UaOtAAcrCXpdNoKc',
  '-N92Uj4v7Uz5USiclgAF',
  '-N92V4d7A3SGuaExbIcn',
  '-N92VEv96sxAXWb1z4aq',
  '-N92VYv8ItG7gvn_S0hn',
  '-N92Ve7hjwWxJ3_fOOML',
  '-N92VxYZB4P7-P0so0Tc',
  '-N92W2jCYu5bRgrH83DG',
  '-N92WItbD6TnXLiaiWMa',
  '-N92Wbt0RUq_Zd3YZO3i',
  '-N92WsjRsyWglZOh-NPz',
  '-N92XDhC97HUpljnKW57',
  '-N92XKT0zHL3NHKySe9l',
  '-N92XRbXUrvnGC2Sr6iz',
  '-N92XXtlew9dol-tM_re',
  '-N92Xe1Ol9JZS5DUznmy',
  '-N92XkpwTbcSLUmUrIyh',
  '-N92Xxdc3mfkyrzDd11Q',
  '-N92Y4Deg9kD7lPTYvu0',
  '-N92YARUNMNy7_QqkUCa',
  '-N92ZYIYqgF9jRa4D7mN',
  '-N92ZcxLTryhxLSssyys',
  '-N92ZiJFUasHjdNBKsbL',
  '-N92ZpDkytV9P1Qh4MjP',
  '-N92ZxJ4k1iLNLgHg_uv',
  '-N92_1yoexhSlw0CVdHk',
  '-N92_7Lc_YB_q4Fw1qym',
  '-N92_D5th8mtDq6pgbNd',
  '-N92_JVfPuCG1pDuo_JS',
  '-N92_pXunDVTWJPdp4GX',
  '-N92_voQM-_i6I6TsqtP',
  '-N92a1xfwVIswkRu_69I',
  '-N92a8_7XyGe6D6FZGWU',
  '-N92aUtfJ4-4HN59gqdr',
  '-N92abHV_5fRhfzNW-HT',
  '-N92aibgxX2xW3yAdke6',
  '-N92b9Gjl2NIY2eA3LrM',
  '-N92bFWYE3iZ2MJIp6w0',
  '-N92bMEmUDLNNncP0M2L',
  '-N92bRKu3s9YwVEXC3sv',
  '-N92bWKIS8sI5Rn_t_nZ',
  '-N92bbGvdxepf-_1833M',
  '-N92bhyZHXjCk2rvWONd',
  '-N92c0BXjTpJlUU1uAas',
  '-N92c5fuNe41wT712khd',
  '-N92cBoWCUjVOn9rSih5',
  '-N92cI6yMpR5oFggyyXy',
  '-N92cO5h7KhpggVZoDeW',
  '-N92cTf-chTTcXjv6P5D',
  '-N92cdJN-MYmpvUo8dmD',
  '-N92cjkSUPz-9WzVkTvM',
  '-N92cpeZkhMcXEycwM7J',
  '-N92g7y7CI-9Gd9ewNtx',
  '-N92gIQ2E_xBBStrPfXZ',
  '-N92gOq2CAfYY1YnP5bP',
  '-N92gWxP6cUsZdeayizB',
  '-N92gc0YWY6-guADDTSC',
  '-N92giE411O2x0hiuCdm',
  '-N92gnlGyjgTch1-mGdp',
  '-N92gv41xG0XH2saXmNj',
  '-N92h210Q1dGZTTR9s9X',
  '-N92h8Z6FU7Rd7nMA9eD',
  '-N92hJVWFxve4xR9XbaF',
  '-N92hPONBTKxcBBvYeE1',
];
